@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

/********** VARIABLES **********/
$lever-support-height: 78px;
$lever-support-width: 93px;
$lever-stem-height: 150px;
$lever-stem-width: 13px;
$lever-hand-height: 52px;
$lever-hand-width: 52px;
$lever-height: $lever-support-height + $lever-stem-height + $lever-hand-height;

// factors for small size
$game-size-medium-factor: 0.5;
$game-size-small-factor: 0.4;

$animation-duration: 0.4s;

/********** ANIMATIONS **********/

@mixin animate-lever($lever-stem-height, $lever-support-height) {
  @keyframes hand-slide-frame {
    0% {
      bottom: calc(#{$lever-stem-height} + #{$lever-support-height});
      filter: brightness(100%);
    }
    100% {
      filter: brightness(70%);
      bottom: $lever-support-height;
    }
  }
  @keyframes lever-slide-frame {
    0% {
      height: $lever-stem-height;
      background: linear-gradient(
        271.78deg,
        #dfa54d 10.42%,
        #472714 24.05%,
        #8d5924 34.76%,
        #eabe69 45.47%,
        #f9f290 55.21%,
        #ae7936 73.71%,
        #6a4421 89.28%,
        #a77c2f 99.99%,
        #674220 107.78%
      );
    }
    100% {
      height: 0px;
      background: linear-gradient(
        271.78deg,
        darken(#dfa54d, 15%) 10.42%,
        darken(#472714, 15%) 24.05%,
        darken(#8d5924, 15%) 34.76%,
        darken(#eabe69, 15%) 45.47%,
        darken(#f9f290, 15%) 55.21%,
        darken(#ae7936, 15%) 73.71%,
        darken(#6a4421, 15%) 89.28%,
        darken(#a77c2f, 15%) 99.99%,
        darken(#674220, 15%) 107.78%
      );
    }
  }
}

@keyframes spinItIE {
  from {
    top: -400%;
  }
  to {
    top: 0%;
  }
}
@keyframes spinIt {
  from {
    top: -200%;
  }
  to {
    top: 200%;
  }
}

.casino {
  /********** COMMON STYLES **********/

  .game-screen {
    display: flex;
    align-items: center;

    .screen-container {
      z-index: 2;
      border-radius: 150px;
      display: flex;
      overflow: hidden;
      position: relative;
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.7), 1px 0px 9px 0px #4a2915;

      // Utiliser background-clip pour l'effet de bordure en dégradé
      background: linear-gradient(white, white) padding-box,
        // Masque le dégradé avec la couleur de fond
        linear-gradient(
            to bottom,
            #cd9645 0%,
            #eec35d 16.2326%,
            #ffd96a 30.7727%,
            #fedf6b 44.5%,
            #e2b454 50.561%,
            #ffe789 63.3785%,
            #e2b454 69.2507%,
            #fad167 77.8035%,
            #bc803a 100%
          )
          border-box;
      border: 12px solid transparent; // Bordure transparente nécessaire pour l'effet

      .screen-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative; // Ajouté pour positionner correctement le pseudo-élément
        // Ajouter un pseudo-élément pour le dégradé de bordure
        &::after {
          content: '';
          position: absolute;
          left: 0;
          height: 110%;
          width: 5px; // Largeur de la bordure souhaitée
          background: linear-gradient(
            90deg,
            #cd9645 43%,
            #eec35d 51.92%,
            #ffd96a 59.91%,
            #fedf6b 67.45%,
            #e2b454 70.77%,
            #ffe789 77.81%,
            #e2b454 81.04%,
            #fad167 85.74%,
            #bc803a 97.93%
          );
          border-top-left-radius: 5px; // Adaptez ces valeurs pour correspondre aux arrondis de votre élément
          border-bottom-left-radius: 5px;
        }
      }

      .screen-column-first:after {
        width: 0;
      }
    }
  }

  /********** MOBILE **********/
  &.mobile {
    .game-screen {
      .game-content {
        height: 100%;
        margin-bottom: 40px;

        .game {
          width: 100%;
          background-size: 507px 530px;
          row-gap: 25px;

          .subtitle {
            padding: 0 50px;
          }
          .odds-text {
            color: rgb(245, 225, 75);
          }

          .screen-container {
            width: 380px;
            height: 160px;
          }

          .screen-column {
            height: 100%;
            width: 34%;

            img {
              width: 60px;
              position: relative;
              margin: 9px;
              user-select: none;
              top: 30%;
              width: 50%;
              //IE
              @media screen and (-ms-high-contrast: active),
                screen and (-ms-high-contrast: none) {
                top: -480%;
              }
            }
            .winnerImg {
              top: 0;
              @media screen and (-ms-high-contrast: active),
                screen and (-ms-high-contrast: none) {
                top: -30%;
              }
            }
          }

          .buttons-area {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .insert-coin {
              display: none;
            }
          }
        }

        .lever {
          display: none;
        }
      }
    }

    /** REALLY SMALL MOBILES **/
    @include mq($until: $smartphone-height, $media-feature: height) {
      .game-screen {
        height: 465px;
        .game-content {
          .game {
            background-size: 507px 465px;
            .screen-container {
              width: 320px;
            }
          }
        }
      }
    }
  }

  /********** DESKTOP **********/
  &.desktop {
    @include animate-lever($lever-stem-height, $lever-support-height);
    .game-content {
      position: relative;
      margin: auto;
      display: flex;
      .game {
        row-gap: 20px;
        .title {
          line-height: 30px;
          text-align: center;
          letter-spacing: 0.03em;
          max-width: inherit;
          position: relative;
        }
        .subtitle {
          display: flex;
          margin-block: 0;
        }
        .odds-text {
          color: rgb(245, 225, 75);
        }

        .description {
          display: flex;
          flex-direction: column;
          padding: 0 0 5px;
          font-size: 17px;

          .or {
            opacity: 0.5;
          }
        }

        .screen-container {
          width: 550px;
          height: 200px;

          .screen-column {
            height: 100%;
            width: 34%;

            img {
              position: relative;
              margin: 9px;
              user-select: none;
              top: 30%;
              width: 50%;
              //IE
              @media screen and (-ms-high-contrast: active),
                screen and (-ms-high-contrast: none) {
                top: -480%;
              }
            }
            .winnerImg {
              top: 0;
              @media screen and (-ms-high-contrast: active),
                screen and (-ms-high-contrast: none) {
                top: -30%;
              }
            }
          }
        }
      }
      .lever {
        position: absolute;
        right: -50px;
        top: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 60px;
        text-align: center;
        cursor: pointer;
        z-index: 1;
        height: $lever-height;

        .slot-hand {
          width: $lever-hand-height;
          height: $lever-hand-width;
          align-self: center;
          background: url('./casino/boule.png');
          position: absolute;
          bottom: calc($lever-support-height + $lever-stem-height);
          &.hand-slide {
            animation: $animation-duration linear 2 alternate hand-slide-frame;
          }
        }
        .slot-lever {
          width: $lever-stem-width;
          height: $lever-stem-height;
          background: url('./casino/levier.png');
          align-self: center;
          position: absolute;
          bottom: $lever-support-height;

          &.lever-slide {
            animation: $animation-duration linear 2 alternate lever-slide-frame;
          }
        }

        .slot-lever-support {
          background: url('./casino/lever.png');
          width: $lever-support-width;
          height: $lever-support-height;
          position: absolute;
          left: -20px;
          bottom: 0;
        }
      }

      .insert-coin {
        position: absolute;
        right: 100px;
        bottom: 100px;
        display: flex;
        margin-left: 4%;
        margin-top: 1%;
        width: 96px;
        height: 37px;
        background-color: $insert-coin-color;
        border-radius: 9px;

        .coin-line {
          margin: auto;
          justify-items: center;
          align-items: center;
          width: 44px;
          height: 0px;
          border-radius: 9px;
          border: 4px solid rgba(0, 0, 0, 0.4);
        }
      }
    }

    @include mq(
      $from: mobile,
      $and: mq($until: m-height, $media-feature: height)
    ) {
      .game-content {
        .game {
          background-size: 550px auto;
          row-gap: 15px;

          .title {
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.03em;
            max-width: inherit;
          }

          .screen-container {
            width: 490px;
            height: 170px;

            .screen-column {
              img {
                //IE
                @media screen and (-ms-high-contrast: active),
                  screen and (-ms-high-contrast: none) {
                  top: -480%;
                }
              }
              .winnerImg {
                top: 0;
                @media screen and (-ms-high-contrast: active),
                  screen and (-ms-high-contrast: none) {
                  top: -50%;
                }
              }
            }
          }

          .buttons-area {
            .insert-coin {
              position: absolute;
              right: 180px;
              bottom: 140px;
              width: 50px;
              height: 10px;
              width: 50px;
              height: 25px;
              border-radius: 6px;

              .coin-line {
                width: 30px;
                border-radius: 6px;
                border: 3px solid rgba(0, 0, 0, 0.4);
              }
            }
          }
        }
        .lever {
          right: -50px;
          top: -20px;
        }
      }
    }

    @include mq(
      $from: mobile,
      $and: mq($until: s-height, $media-feature: height)
    ) {
      $lever-support-height: $lever-support-height * $game-size-medium-factor;
      $lever-support-width: $lever-support-width * $game-size-medium-factor;
      $lever-stem-height: $lever-stem-height * $game-size-medium-factor;
      $lever-stem-width: $lever-stem-width * $game-size-medium-factor;
      $lever-hand-height: $lever-hand-height * $game-size-medium-factor;
      $lever-hand-width: $lever-hand-width * $game-size-medium-factor;
      $lever-height: $lever-support-height + $lever-stem-height + $lever-hand-height;

      @include animate-lever($lever-stem-height, $lever-support-height);

      .game-content {
        .game {
          .title {
            font-size: 14px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.03em;
            max-width: inherit;
          }

          .screen-container {
            width: 300px;
            height: 110px;
            .screen-column {
              img {
                //IE
                @media screen and (-ms-high-contrast: active),
                  screen and (-ms-high-contrast: none) {
                  top: -480%;
                  width: 50px;
                }
              }
              .winnerImg {
                top: 0;
                @media screen and (-ms-high-contrast: active),
                  screen and (-ms-high-contrast: none) {
                  top: 0;
                }
              }
            }
          }
        }

        .lever {
          height: $lever-height;
          top: 50px;
          right: -40px;

          .slot-lever-support {
            width: $lever-support-width;
            height: $lever-support-height;
            background-size: $lever-support-width;
            left: 5px;
          }

          .slot-lever {
            width: $lever-stem-width;
            height: $lever-stem-height;
            background-size: $lever-stem-width;
            bottom: $lever-support-height;
          }
          .slot-hand {
            width: $lever-hand-height;
            height: $lever-hand-width;
            background-size: $lever-hand-width;
            bottom: calc($lever-support-height + $lever-stem-height);
            align-self: center;
          }

          .buttons-area {
            // margin-left: 7% !important;

            .insert-coin {
              position: absolute;
              right: 100px;
              bottom: 100px;
              width: 50px;
              height: 10px;
              width: 50px;
              height: 25px;
              border-radius: 6px;

              .coin-line {
                width: 30px;
                border-radius: 6px;
                border: 3px solid rgba(0, 0, 0, 0.4);
              }
            }
          }
        }
      }
    }

    @include mq(
      $from: mobile,
      $and: mq($until: xs-height, $media-feature: height)
    ) {

      $lever-support-height: $lever-support-height * $game-size-small-factor;
      $lever-support-width: $lever-support-width * $game-size-small-factor;
      $lever-stem-height: $lever-stem-height * $game-size-small-factor;
      $lever-stem-width: $lever-stem-width * $game-size-small-factor;
      $lever-hand-height: $lever-hand-height * $game-size-small-factor;
      $lever-hand-width: $lever-hand-width * $game-size-small-factor;
      $lever-height: $lever-support-height + $lever-stem-height + $lever-hand-height;

      @include animate-lever($lever-stem-height, $lever-support-height);

      .game-content {
        .game {
          min-height: 200px;
          background-size: 230px auto;
          row-gap: 0;

          .title {
            font-size: 10px;
          }

          .screen-container {
            width: 200px;
            height: 90px;
          }
        }

        .lever {
          height: $lever-height;
          top: 35px;
          right: -40px;

          .slot-lever-support {
            width: $lever-support-width;
            height: $lever-support-height;
            background-size: $lever-support-width;
            left: 10px;
          }

          .slot-lever {
            width: $lever-stem-width;
            height: $lever-stem-height;
            background-size: $lever-stem-width;
            bottom: $lever-support-height;
          }
          .slot-hand {
            width: $lever-hand-height;
            height: $lever-hand-width;
            background-size: $lever-hand-width;
            bottom: calc($lever-support-height + $lever-stem-height);
            align-self: center;
          }

          .buttons-area {
            // margin-left: 7% !important;

            .insert-coin {
              position: absolute;
              right: 100px;
              bottom: 100px;
              width: 50px;
              height: 10px;
              width: 50px;
              height: 25px;
              border-radius: 6px;

              .coin-line {
                width: 30px;
                border-radius: 6px;
                border: 3px solid rgba(0, 0, 0, 0.4);
              }
            }
          }
        }

        .buttons-area {
          .insert-coin {
            width: 50px;
            height: 25px;
            border-radius: 6px;

            .coin-line {
              width: 30px;
              border-radius: 6px;
              border: 3px solid rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
    }
  }
}
