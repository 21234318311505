* {
  -ms-overflow-style: none;
  box-sizing: border-box;
  white-space: wrap;
  align-content: center;
}
body {
  margin: 0;
  padding: 0;
  white-space: wrap;
  box-sizing: content-box;
  overflow-x: hidden;
  width: 100.2vw;
}

::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0px;
  background: transparent;
}

.mobile {
  overflow: auto;
  height: 100vh;
}

#root {
  .mobile {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
  }

  #main-container {
    height: 100vh;
  }
}

body {
  margin: 0;
  flex: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}

// @media screen and (max-width: 768px) {
//   ::-webkit-scrollbar {
//     -webkit-appearance: none;

//     width: 0px;
//     background: transparent;
//   }
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Righteous';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Righteous-Regular.ttf');
}
@font-face {
  font-family: 'Open sans';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/OpenSans-Regular.ttf');
}
body {
  font-family: 'Open sans';
}
