@import '~@mcaskill/sass-mq';
@import '../../../../../game/resources/assets/styles/form';

.generic-error-message {
  @extend .error-message;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px auto;
  text-align: left;
  color: $color-secondary !important;
}
