.button-area {
  display: flex;
  gap: 10px;
  margin: 0 auto;
  align-self: center;
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
.mobile {
  .button-area {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    gap: 0px;
  }
}

.can-not-play {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  height: 100vh;
}
.wrapper {
  border-radius: 20px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}
