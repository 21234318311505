@import "../../../../../shop/assets/styles/variables.scss";

.radio {
  font-family: Open Sans;
  display: flex;
  justify-items: center;
  gap: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.02em;
  cursor: pointer;
  color: $color-secondary;
  text-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    margin-bottom: 10px;
  }
  .checked {
    opacity: 1 !important;
  }
  .checked-text {
    color: $color-secondary;
  }
  .radioBox {
    width: 15px;
    height: 15px;
    margin-top: 6px;
    min-width: 10px;
    border: 2px solid $color-secondary;
    border-radius: 30px;
    opacity: 0.6;
    .circle {
      width: 7px;
      height: 7px;
      border: 2px solid $color-secondary;
      background-color: $color-secondary;
      border-radius: 30px;
      margin: 0 auto;
    }
  }
  .radioTitle {
    max-width: 300px;
    text-align: start;
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      padding-left: 10px;
    }
  }
}
