$color-primary: #2e70de;
$color-light-primary: #a9c9ff;
$color-secondary: #ffffff;
$color-grey: #c4c4c4;
$color-info: #e8f1fb;
$cta-background-color: #ffffff;
$cta-text-color: #2e70de;
$input-error-color: rgba(0, 0, 0, 0.6);
$input-info-color: rgba(255, 255, 255, 0.6);
$footer-text-color: #1e1717;
$insert-coin-color: rgba(0, 0, 0, 0.2);
//SLOT MACHINE
$color-machine-levier: #7cabf9;
$color-machine-head: #023587;
$color-machine-border: #2b6cd6;
//WEB RESPONSIVE
$xs-height: 330px;
$s-height: 565px;
$m-height: 745px;

$mobile-landscape: 639px;
$smartphone-height: 641px;
$iphone-x-height: 823px;
$smartphone-width: 414px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;

// Breakpoint
$mq-breakpoints: (
  mobile-sm: 350px,
  mobile: $mobile-landscape,
  tablet-portrait: $tablet-portrait,
  tablet: $tablet-landscape,
  desktop-sm: 1366px,
  desktop: 1440px,
  desktop-large: 1600px,
  desktop-xlarge: 1681px,
  mobileLandscape: $mobile-landscape,
  xs-height: $xs-height,
  s-height: $s-height,
  m-height: $m-height,
);

/*
$mq-show-breakpoints: (
  mobile-sm,
  mobile,
  tablet-portrait,
  tablet,
  desktop-sm,
  desktop,
  desktop-large,
  desktop-xlarge,
  mobileLandscape,
  xs-height,
  s-height,
  m-height
);*/
