@import "../../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";
@import "../../../../../game/resources/assets/styles/form";

.input-error-message {
  @extend .error-message;
  position: relative;
  border-top: 2px solid $input-error-color;
  bottom: 2px;
  text-align: right;

  img {
    position: relative;
    bottom: 25px;
  }
}

.autocomplete-list {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;
  width: 100%; // Make the list the same width as the input
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  top: 60px;

  .autocomplete-item {
    padding: 10px;
    border-bottom: 1px solid #ddd; // Add a border between items
    display: flex;
    flex-direction: column;
    gap: 8px;

    .autocomplete-top {
      font-weight: bold;
    }

    .autocomplete-bottom {
      font-size: .8rem;
    }

    &:last-child {
      border-bottom: none; // Remove the border from the last item
    }

    &:hover {
      background-color: #f1f1f1;
    }
  }
}
