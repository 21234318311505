@import '../../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.legal-container {
  font-family: Open Sans;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: white;
  width: inherit;

  .legal-content {
    font-family: 'Righteous';
    padding: 50px 20px;
    border-radius: 20px;
    width: inherit;
    height: 75vh;
    max-height: fit-content;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    text-align: left;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 30px;
      letter-spacing: 0.03em;
    }
    .back-button {
      background: url('../../../../../shop/assets/img/back.png') no-repeat
        center;
      width: 40px;
      height: 23px;
      position: absolute;
      top: 10px;
      left: 0;
      display: block;
      padding: 20px 25px;
      border-radius: 20px;
    }
    h1 {
      line-height: normal;
    }
    .legal-text {
      & > div {
        a {
          text-decoration: none;
          color: $color-secondary;
        }
        & > div {
          padding: 5px 0;
        }
      }
    }
  }
}
@include mq($until: tablet-portrait) {
  .legal-container {
    font-size: 12px;
    .legal-content {
      width: 100%;
      padding: 10px 5px;
      flex-direction: column;
      justify-content: flex-start;

      h2 {
        font-size: 22px;
      }

      .back-button {
        position: relative;
        float: left;
        top: 10px;
        padding: 20px 25px;
      }
    }
  }
}
