@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.title {
  font-family: Righteous;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  color: $color-secondary;
  max-width: 500px;
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.subtitle {
  font-family: Righteous;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  color: $color-secondary;
  max-width: 550px;
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 389px;
  }
}
.description {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  color: $color-secondary;
  max-width: 550px;
  margin-block: 10px;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 389px;
  }
}

.agree {
  display: block;
  max-width: 500px;
  font-style: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: $color-secondary;
  text-align: left;
  .checkbox {
    margin-right: 5px;
    float: left;
  }
  a {
    color: $color-secondary;
  }
}

@include mq($from: mobile, $and: mq($until: m-height, $media-feature: height)) {
  .title {
    font-size: 45px;
    line-height: 50px;
    max-width: 500px;
  }
  .subtitle {
    font-size: 22px;
    line-height: inherit;
    max-width: 450px;
  }
  .description {
    font-size: 20px;
    line-height: inherit;
    max-width: 500px;
  }

  .agree {
    display: block;
    max-width: 500px;
    font-style: normal;
    max-width: 400px;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: $color-secondary;
    gap: 5px;
    .checkbox {
      margin-right: 5px;
      float: left;
    }
    a {
      color: $color-secondary;
    }
  }
}

@include mq($from: mobile, $and: mq($until: s-height, $media-feature: height)) {
  .title {
    font-size: 35px;
    line-height: 40px;
    max-width: 400px;
  }
  .subtitle {
    font-size: 18px;
    max-width: 280px;
  }
  .description {
    font-size: 13px;
    max-width: 300px;
  }
  .agree {
    display: block;
    max-width: 500px;
    font-style: normal;
    max-width: 400px;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: $color-secondary;
    .checkbox {
      margin-right: 5px;
      float: left;
    }
    a {
      color: $color-secondary;
    }
  }
}

@include mq(
  $from: mobile,
  $and: mq($until: xs-height, $media-feature: height)
) {
  .title {
    font-size: 15px;
    max-width: 170px;
  }
  .subtitle {
    font-size: 10px;
    max-width: 170px;
  }
  .description {
    max-width: 280px;
  }
}

@include mq($until: mobile) {
  .title {
    font-size: 40px;
    line-height: 45px;
    max-width: 370px;
  }
  .subtitle {
    font-size: 22px;
    line-height: 30px;
    padding: 0 10px;
  }
  .description {
    max-width: 332px;
  }
  .agree {
    display: block;
    max-width: 300px;
    font-style: normal;
    max-width: 400px;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: $color-secondary;
    .checkbox {
      margin-right: 5px;
      float: left;
    }
    a {
      color: $color-secondary;
    }
  }
}
