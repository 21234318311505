@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

//***** ANIMATIONS ******//

//Scatch

@mixin vanish() {
  -webkit-animation: fadeInFromNone 2s forwards;
  -moz-animation: fadeInFromNone 2s forwards;
  -o-animation: fadeInFromNone 2s forwards;
  animation: fadeInFromNone 2s forwards;
}

@mixin appears() {
  -webkit-animation: fadeToFront 2s forwards;
  -moz-animation: fadeToFront 2s forwards;
  -o-animation: fadeToFront 2s forwards;
  animation: fadeToFront 2s forwards;
}

@keyframes fadeInFromNone {
  0% {
    display: block;
    opacity: 1;
  }
  50% {
    display: block;
    opacity: 1;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes fadeToFront {
  0% {
    display: none;
    opacity: 0;
  }
  50% {
    display: none;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

//******************* //

.wheel {
  .wheel-slide {
    animation: 0.4s linear 2 alternate wheel-slide-frame;
  }
  .spinTxt {
    color: white;
    z-index: 10;
    font-size: 13px;
  }
  .spinBtnElt {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .marker-anim {
    animation: 0.05s ease-in 210 alternate marker-anim-frame;
  }

  &.mobile {
    .game-screen {
      @include mq($until: $smartphone-height, $media-feature: height) {
        height: 465px;
      }
      .game-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 88%;

        .title {
          font-size: 20px;
          width: 262px;
        }

        .game {
          width: 100%;
          row-gap: 10px;
          .title {
            margin-bottom: 0;
          }
          .handle-trigger {
            display: none;
          }
          .wheel-container {
            display: flex;
            justify-content: center;
            align-items: center;
            .spinButton {
              position: absolute;
              z-index: 10;
            }
          }
          @include mq($until: $smartphone-height, $media-feature: height) {
            background-size: 507px 465px;
          }

          .buttons-area {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  &.desktop {
    .game {
      .subtitle {
        margin-bottom: 0px;
        width: 500px;
      }
      row-gap: 30px;
      .play-button {
        margin: 5px 0 15px 0;
      }
      .handleGap {
        transform: rotate(-18deg);
      }
      .handleStick {
        position: absolute;
        left: -15px;
        top: -55px;
        z-index: 20;
        transform: rotate(-14deg);
      }
      .handle-trigger {
        position: absolute;
        width: 35px;
        height: 35px;
        top: 50px;
        right: 75px;
        transform: rotate(16deg);
        &:hover {
          cursor: pointer;
        }
      }
      .wheel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .spinButton {
          position: absolute;
          z-index: 10;
        }
      }

      @include mq(
        $from: mobile,
        $and: mq($until: m-height, $media-feature: height)
      ) {
        row-gap: 8px;

        .subtitle {
          margin-bottom: 0px;
          width: 400px;
        }
        .handleStick {
          position: absolute;
          left: -15px;
          top: -55px;
          z-index: 20;
          transform: rotate(-14deg);
        }
        .handle-trigger {
          position: absolute;
          width: 35px;
          height: 35px;
          top: 70px;
          right: 120px;
          transform: rotate(7deg);
          &:hover {
            cursor: pointer;
          }
        }
        .wheel-container {
          display: flex;
          justify-content: center;
          align-items: center;
          .spinButton {
            position: absolute;
            z-index: 10;
          }
        }
      }

      @include mq(
        $from: mobile,
        $and: mq($until: s-height, $media-feature: height)
      ) {
        .subtitle {
          font-size: 18px;
        }
        .handleStick {
          position: absolute;
          left: -0px;
          top: -30px;
          z-index: 20;
          transform: rotate(-14deg);
        }
        .handle-trigger {
          position: absolute;
          width: 35px;
          height: 35px;
          top: 100px;
          right: 210px;
          transform: rotate(0deg);
          &:hover {
            cursor: pointer;
          }
        }

        .game-screen {
          background-size: 500px 370px;

          .game-screen-content {
            row-gap: 10px;
          }
        }
        .wheel-container {
          display: flex;
          justify-content: center;
          align-items: center;
          .spinButton {
            position: absolute;
            z-index: 10;
          }
        }
      }

      @include mq(
        $from: mobile,
        $and: mq($until: xs-height, $media-feature: height)
      ) {
        .game-screen {
          background-size: 250px auto;
          .game-screen-content {
            row-gap: 0;
          }
        }
      }
    }
  }
}
.flip {
  &.mobile {
    .game {
      row-gap: 30px !important;
      & > .title {
        position: relative;
        font-size: 30px;
      }
      .subtitle {
        width: 70%;
        margin-left: 15%;
      }
      .buttons-area {
        .title {
          margin-bottom: 0 !important;
          width: 160px;
          font-size: 16px;
          line-height: normal;
        }
      }
    }
    .card-style {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100vw;
      gap: 10px;
    }
    .card {
      width: 29%;
      height: 160px;
    }
  }
  &.desktop {
    .game {
      row-gap: 30px;
    }
    .card-style {
      max-width: 75vh;
      display: flex;
      flex-direction: row;
      gap: 0px 30px;
      justify-content: center;
      align-items: center;
    }
    .card {
      width: 140px;
      height: 200px;
    }
    .img-center-card {
      width: calc(140px / 1.4);
      height: calc(140px / 1.4);
    }

    @include mq(
      $from: mobile,
      $and: mq($until: m-height, $media-feature: height)
    ) {
      .card-style {
        max-width: 380px;
        gap: 0px 25px;
      }
      .card {
        width: 120px;
        height: 160px;
      }
      .img-center-card {
        width: calc(120px / 1.4);
        height: calc(120px / 1.4);
      }
    }

    @include mq(
      $from: mobile,
      $and: mq($until: s-height, $media-feature: height)
    ) {
      .game {
        row-gap: 30px;
      }
      .card-style {
        max-width: 280px;
        gap: 0px 20px;
      }
      .card {
        width: 80px;
        height: 120px;
      }
      .img-center-card {
        width: calc(80px / 1.4);
        height: calc(80px / 1.4);
      }
    }
  }
}

.scratch {
  .auto-scratch-result {
    position: absolute;
    top: 50%;
    left: 50%;
    // when winnning
    transform: translate(-50%, -50%) rotate(-10deg);
    // when lost
    &.lose {
      transform: translate(-60%, -70%) rotate(-10deg);
    }
  }

  .screen-container-scratch {
    &.animation {
      .auto-scratch-front {
        @include vanish;
      }
      .auto-scratch-container {
        height: 350px;
      }
    }
  }

  &.mobile {
    .game-content {
      // width: 100%;
      .title {
        font-size: 28px;
        width: 80%;
      }
      .game {
        position: relative;
        top: 20px;
        width: 100%;
        height: 100%;
      }
      .scratch-layers {
        height: fit-content;
        width: fit-content;
      }
      .scratch-here-img {
        position: absolute;
        left: auto;
        right: 10px;
        bottom: -8%;
        pointer-events: none;
        width: 40%;
      }
      .screen-container-scratch {
        margin-top: 12px;
        & > div {
          margin: 0 auto;
          canvas:nth-child(1),
          canvas:nth-child(3) {
            position: absolute;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            margin-top: 2px;
            margin-left: 8px;
          }
        }
        div > canvas:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-10deg);
        }
        &.lose div > canvas:nth-child(2) {
          transform: translate(-50%, -60%);
        }
        .auto-scratch-txt {
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 18px;
          rotate: -10deg;
          transform: translate(-50%, -50%);
          margin-top: 30px;
          margin-left: 10px;
        }
        .auto-scratch-front {
          display: none;
        }
      }
    }
  }
  &.desktop {
    .scratch-layers {
      height: fit-content;
      width: fit-content;
    }
    .scratch-here-img {
      position: absolute;
      right: 30px;
      left: auto;
      bottom: 60px;
      pointer-events: none;
    }
    .screen-container-scratch {
      .canvas {
        width: auto;
        height: auto;
        min-height: 352px !important;
        width: 100%;
        & > div {
          margin: 0 auto;
          min-height: 352px !important;
          padding-top: 0 !important;
          canvas:nth-child(1),
          canvas:nth-child(3) {
            position: absolute;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            margin-top: -1px;
            margin-left: 1px;
          }
        }
        div > canvas:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-10deg) !important;
          margin-top: -5px;
          margin-left: -12px;
        }
        &.lose div > canvas:nth-child(2) {
          transform: translate(-50%, -50%) !important;
          margin-top: -30px;
          margin-left: -22px;
        }
      }
    }
    .game {
      @include mq(
        $from: mobile,
        $and: mq($until: m-height, $media-feature: height)
      ) {
        .buttons-area {
          margin-top: -40px;
        }
      }
      @include mq(
        $from: mobile,
        $and: mq($until: s-height, $media-feature: height)
      ) {
        .buttons-area {
          margin-top: -20px;
        }
      }
    }
  }
}
