@import "./../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

.refund-options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: self-start;
  font-family: Open Sans;
  row-gap: 24px;
}
.errors {
  min-height: 20px;
}
@include mq(
  $from: tablet-portrait,
  $and: mq($until: mobileLandscape, $media-feature: height)
) {
  .refund-content {
    form {
      input[type="text"] {
        font-size: 3.2vh;
        padding: inherit;
      }

      .agree {
        display: block;
        max-width: 500px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: $color-light-primary;
        gap: 5px;
        margin-bottom: 20px;

        .checkbox {
          margin-right: 5px;
          float: left;
        }
        a {
          color: $color-light-primary;
        }
      }
    }
  }
}

@include mq($until: tablet-portrait) {
  .refund-content {
    max-width: 350px;

    @media screen and (orientation: landscape) {
      max-width: 250px;
    }

    .subtitle {
      font-size: 3.5vh;
      margin: 0 auto;
      width: 100%;
      line-height: inherit;
    }

    form {
      input[type="text"] {
        font-size: 2.5vh;
        padding-bottom: 1px;
      }

      label {
        font-size: 2vh;
        line-height: inherit;
        top: 0;
      }

      .agree {
        display: block;
        max-width: 500px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: $color-light-primary;
        gap: 5px;
        margin-bottom: 20px;

        .checkbox {
          margin-right: 5px;
          float: left;
        }
        a {
          color: $color-light-primary;
        }
      }
    }
  }
}

@include mq($until: mobileLandscape, $media-feature: height) {
  .content {
    grid-row-gap: initial;
    row-gap: initial;

    .refund-options {
      grid-row-gap: 5px;
      row-gap: 5px;
    }
  }
}

form {
  .inputFile {
    .uploadFile {
      cursor: pointer;
      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        margin-top: 16px;
      }
      display: flex;
      flex-direction: row;
      gap: 16px;

      .uploadImg {
        border: 2px solid $color-secondary;
        border-radius: 54px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: $color-secondary;
        }
        @media screen and (-ms-high-contrast: active),
          screen and (-ms-high-contrast: none) {
          margin-right: 5px;
        }
      }

      .uploadLabel {
        color: $color-secondary;
        left: 25.55%;
        right: 0%;
        top: 24.07%;
        bottom: 25.93%;
        font-family: Righteous;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 27px;
        display: flex;
        align-items: center;
      }
    }
  }
}

@include mq($from: mobile, $and: mq($until: s-height, $media-feature: height)) {
  .stepper-inputs {
    grid-row-gap: 10px;
    row-gap: 10px;
    margin-bottom: 10px;
    form {
      .inputFile .uploadFile {
        gap: 10px;

        .uploadImg {
          padding: 5px;
          width: 30px;
          height: 30px;
        }

        .uploadLabel {
          font-size: 13px;
        }
      }
    }
  }
}
