@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';
.content-up {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

@include mq($from: mobile, $and: mq($until: s-height, $media-feature: height)) {
  .content-up {
    width: 250px;
    img {
      width: 30px;
    }
  }
  .lips {
    width: 30px;
    margin-top: 0;
  }
}

@include mq(
  $from: mobile,
  $and: mq($until: xs-height, $media-feature: height)
) {
  .content-up {
    width: 200px;
    img {
      width: 15px;
    }
  }
  .lips {
    width: 15px;
    margin-top: 20px;
  }
}

@include mq($until: mobile) {
  .content-up {
    width: 80vw;
  }
}
