@import "./../../../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

.card {
  position: relative;
  // perspective: 50px;
  margin-bottom: 10px;

  &:not(.showed):hover {
    cursor: pointer;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50px);
    width: 90%;
  }

  .svg-classname-front {
    * {
      opacity: 0.3;
    }
    width: 70%;
    transform: translate(-50%, -55px);
  }
  .svg-classname-behind {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -55px);
  }
  .card__lost {
    text-align: center;
  }
  .corner-logo-card {
    width: 20%;
    position: absolute;
    margin: 3px;
    &:nth-child(1) {
      top: 0;
      left: 0;
    }
    &:nth-child(2) {
      bottom: 0;
      right: 0;
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.front,
.back {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  backface-visibility: hidden;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
  &:hover {
    box-shadow: 0 0 25px black;
  }
}

.front {
  background: $color-primary;
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  color: white;
  background-image: url("../../../../../../shop/assets/img/pattern.png");
  background-position: center, center;
  background-size: cover;
  border: 5px solid white;
}

.back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .back-text {
    text-transform: uppercase;
    font-weight: bolder;
  }
}
