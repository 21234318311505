.share-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .subtitle {
    font-size: 20px;
  }
}

.share-btn-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70%;
  margin-top: 20px;
  gap: 12px;
}