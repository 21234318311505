@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.logo {
  position: relative;
  top: 40px;
  margin-inline: auto;
  z-index: 100000;
  margin-bottom: 15px;
}

.desktop .logo img {
  max-height: 80px;
  width: auto;
}

@include mq($until: s-height, $media-feature: height) {
  .logo {
    top: 15px;
    text-align: center;
    position: absolute;

    img {
      width: 100px;
      margin-bottom: 20px;
    }
  }
}

@include mq($until: mobile) {
  .logo {
    padding-top: 26px;
    text-align: center;
    position: initial;
    img {
      max-width: 120px;
    }
    @include mq($until: $smartphone-height, $media-feature: height) {
      margin-top: 14px;
    }
  }
}
